import axios from "axios";

export const HTTP = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL_API,
  timeout: 1000 * 1000,
});


export const HTTPTEST = axios.create({
  baseURL: 'http://13.250.17.107',
  timeout: 1000 * 1000,
});
