export default {
  ADD_LOTTERYS(state, payload) {
    function makeid(length) {
      var result           = [];
      var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      var charactersLength = characters.length;
      for ( var i = 0; i < length; i++ ) {
        result.push(characters.charAt(Math.floor(Math.random() * charactersLength)));
      }  
      return result.join('');
    }

    payload['id'] = makeid(6)
    payload['createDate'] = new Date()
    state.loterys.push(payload)
  },
  UPDATE_LOTTERYS(state, payload) {
    let index = state.loterys.fineIndex(f => f.id === payload.id)
    state.loterys[index] = payload
  },
  DELETE_LOTTERYS(state, id) {
    let index = state.loterys.fineIndex(f => f.id === id)
    state.loterys.splice(index,1)
  },
};
