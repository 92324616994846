<template>
  <div id="app">
    <div class="t-right">
      <el-button
        @click="dialogHotListVisible = true"
        type="warning"
        size="small"
      >
        เลขดัง
      </el-button>
    </div>
    <el-form :model="formLottery" ref="formLottery" class="lottery-box">
      <el-row :gutter="20" class="pd-y-5">
        <el-col :xl="6" :lg="6" :md="6" :sm="6" :xs="24" class="top-input">
          <el-form-item label="เลข" prop="number">
            <el-input
              v-model="formLottery.number"
              @keyup.enter.native="submitForm"
              type="number"
              maxlength="3"
              @input="replaceChar"
              id="number"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :xl="6" :lg="6" :md="8" :sm="8" :xs="24">
          <div class="price-box">
            <el-form-item label="เต็ง" prop="price">
              <vue-numeric
                v-model="formLottery.price"
                class="number"
                @keyup.enter.native="submitForm"
              ></vue-numeric>
            </el-form-item>
            <span class="pd-x-4">X</span>
            <el-form-item label="โต๊ด" prop="priceFlib">
              <vue-numeric
                v-model="formLottery.priceFlib"
                class="number"
                @keyup.enter.native="submitForm"
              ></vue-numeric>
            </el-form-item>
          </div>
        </el-col>

        <el-col :xl="2" :lg="3" :md="4" :sm="5" :xs="24" class="flex y-space">
          <el-checkbox-group
            v-model="formLottery.type"
            @keyup.enter.native="submitForm"
          >
            <el-checkbox :label="1">บน</el-checkbox>
            <el-checkbox :label="0">ล่าง</el-checkbox>
          </el-checkbox-group>
        </el-col>
        <el-col
          :xl="10"
          :lg="9"
          :md="6"
          :sm="5"
          :xs="24"
          class="hidden-xs-only"
        >
          <el-button
            @click="submitForm"
            type="primary"
            class="save"
            :loading="loadingSave"
            ><span class="pd-x-4">เพิ่ม</span></el-button
          >
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :sm="2" :xs="8" class="pd-y-5">
          <el-button
            @click="addPriceOne(200)"
            :loading="loadingSave"
            style="width: 100%"
            >200</el-button
          >
        </el-col>
        <el-col :sm="2" :xs="8" class="pd-y-5">
          <el-button
            @click="addPriceOne(300)"
            :loading="loadingSave"
            style="width: 100%"
            >300</el-button
          >
        </el-col>
        <el-col :sm="2" :xs="8" class="pd-y-5">
          <el-button
            @click="addPriceOne(400)"
            :loading="loadingSave"
            style="width: 100%"
            >400</el-button
          >
        </el-col>

        <el-col :sm="2" :xs="8" class="pd-y-5">
          <el-button
            @click="addPriceOne(500)"
            :loading="loadingSave"
            style="width: 100%"
            >500</el-button
          >
        </el-col>
        <el-col :sm="2" :xs="8" class="pd-y-5">
          <el-button
            @click="addPriceOne(600)"
            :loading="loadingSave"
            style="width: 100%"
            >600</el-button
          >
        </el-col>
        <el-col :sm="2" :xs="8" class="pd-y-5">
          <el-button
            @click="addPriceOne(700)"
            :loading="loadingSave"
            style="width: 100%"
            >700</el-button
          >
        </el-col>
        <el-col :sm="2" :xs="8" class="pd-y-5">
          <el-button
            @click="addPriceOne(800)"
            :loading="loadingSave"
            style="width: 100%"
            >800</el-button
          >
        </el-col>
        <el-col :sm="2" :xs="8" class="pd-y-5">
          <el-button
            @click="addPriceOne(900)"
            :loading="loadingSave"
            style="width: 100%"
            >900</el-button
          >
        </el-col>
        <el-col :sm="2" :xs="8" class="pd-y-5">
          <el-button
            @click="addPriceOne(1000)"
            :loading="loadingSave"
            style="width: 100%"
            >1000</el-button
          >
        </el-col>
        <el-col :sm="8" :xs="12" class="pd-y-5">
          <el-button
            @click="addPrice(100)"
            :loading="loadingSave"
            style="width: 100%"
            >100 X 100</el-button
          >
        </el-col>
        <el-col :sm="8" :xs="12" class="pd-y-5">
          <el-button
            @click="addPrice(200)"
            :loading="loadingSave"
            style="width: 100%"
            >200 X 200</el-button
          >
        </el-col>
        <el-col :sm="8" :xs="12" class="pd-y-5">
          <el-button
            @click="addPrice(300)"
            :loading="loadingSave"
            style="width: 100%"
            >300 X 300</el-button
          >
        </el-col>
        <el-col :sm="8" :xs="12" class="pd-y-5">
          <el-button
            @click="addPrice(500)"
            :loading="loadingSave"
            style="width: 100%"
            >500 X 500</el-button
          >
        </el-col>
        <el-col :sm="8" :xs="12" class="pd-y-5">
          <el-button
            @click="addPrice(1000)"
            :loading="loadingSave"
            style="width: 100%"
            >1000 X 1000</el-button
          >
        </el-col>
        <el-col :sm="8" :xs="12" class="pd-y-5">
          <el-button
            @click="addPrice(2000)"
            :loading="loadingSave"
            style="width: 100%"
            >2000 X 2000</el-button
          >
        </el-col>
        <el-col
          :xl="10"
          :lg="9"
          :md="6"
          :sm="5"
          :xs="24"
          class="hidden-sm-and-up y-space"
        >
          <el-button
            @click="submitForm"
            type="primary"
            class="save"
            :loading="loadingSave"
            ><span class="pd-x-4">เพิ่ม</span></el-button
          >
        </el-col>
      </el-row>
    </el-form>

    <el-divider></el-divider>
    <el-form :model="filter" class="pd-b-4">
      <h3 class="t-left pd-b-4 mg-y-less-only-mobile">ค้นหา</h3>
      <el-row :gutter="20">
        <el-col :xl="6" :lg="6" :md="10" :sm="8" :xs="24">
          <el-form-item label="ตัวเลข" prop="unit">
            <el-input
              placeholder="กรุณาพิมพ์เลขที่ต้องการค้นหา"
              prefix-icon="el-icon-search"
              v-model="filter.number"
            >
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :xl="3" :lg="4" :md="6" :sm="8" :xs="24">
          <el-form-item label="หลัก">
            <el-checkbox-group v-model="filter.unit">
              <el-checkbox :label="2">2 ตัว</el-checkbox>
              <el-checkbox :label="3">3 ตัว</el-checkbox>
              <el-checkbox :label="1">วิ่ง</el-checkbox>
            </el-checkbox-group>
          </el-form-item>
        </el-col>
        <el-col :xl="2" :lg="3" :md="8" :sm="6" :xs="24">
          <el-form-item label="ประเภท">
            <el-checkbox-group v-model="filter.type">
              <el-checkbox :label="1">บน</el-checkbox>
              <el-checkbox :label="0">ล่าง</el-checkbox>
            </el-checkbox-group>
          </el-form-item>
        </el-col>
        <el-col :xl="13" :lg="11" :md="24" :sm="24" :xs="24" class="top-30">
          <el-form-item label="เงื่อขไข">
            <el-checkbox-group
              v-model="filter.condition"
              @change="filterLottery"
            >
              <el-checkbox :label="1">มากกว่าหรือเท่ากับ 500</el-checkbox>
              <el-checkbox :label="2">ค่ามากสุด 3 อันดับแรก</el-checkbox>
              <el-checkbox :label="3">มากกว่าหรือเท่ากับ 600</el-checkbox>
            </el-checkbox-group>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

    <div class="t-right pd-b-4 action-box main">
      <el-button
        @click="csvExport"
        type="success"
        icon="el-icon-document"
        size="mini"
        plain
        class="btn-menu"
      >
        Export excel
      </el-button>
      <el-button
        @click="delLottery(multipleSelection, 1)"
        :loading="loadingDel"
        type="danger"
        icon="el-icon-delete"
        size="mini"
        plain
        class="btn-menu"
      >
        ลบที่เลือก
        <span v-if="multipleSelection.length > 0"
          >({{ multipleSelection.length }})</span
        >
      </el-button>

      <el-popconfirm
        title="คุณแน่ใจใช่ไหมที่จะลบข้อมูลทั้งหมด"
        @confirm="delLottery(lotterys, 1)"
        confirm-button-text="ใช่"
        cancel-button-text="ยกเลิก"
        class="button-popconfirm"
      >
        <el-button
          slot="reference"
          :loading="loadingDel"
          icon="el-icon-document-remove"
          size="mini"
          plain
          class="btn-menu"
        >
          ลบทั้งหมด
        </el-button>
      </el-popconfirm>
      <span class="btn-menu t-right"
        ><span style="font-weight: 600">รวม: </span
        >{{ total | commaInteger }} บาท</span
      >
    </div>

    <el-table
      v-loading="loadingTable"
      :data="lotterySumNumbers.limit"
      @selection-change="handleSelectionChange"
      style="width: 100%"
      border
      ref="lotterySumNumbers"
    >
      <el-table-column type="selection" width="35"> </el-table-column>
      <el-table-column prop="number" header-align="center" align="center">
        <template slot="header">
          <span @click="sortTable('number')" class="table-header"
            >เลข
            <i
              v-if="sortColumn === 'number'"
              :class="sort === 1 ? 'el-icon-bottom' : 'el-icon-top'"
            ></i
          ></span>
        </template>

        <template slot-scope="scope">
          <el-button
            @click="(lotterySub = scope.row.sub), (dialogLotteryVisible = true)"
            type="text"
            >{{ scope.row.number }}</el-button
          >
        </template>
      </el-table-column>
      <el-table-column
        label="เต็ง"
        header-align="center"
        align="center"
        prop="price"
      >
        <template slot="header">
          <span @click="sortTable('price')" class="table-header"
            >เต็ง
            <i
              v-if="sortColumn === 'price'"
              :class="sort === 1 ? 'el-icon-bottom' : 'el-icon-top'"
            ></i
          ></span>
        </template>
        <template slot-scope="scope">
          {{ scope.row.price | commaInteger }}
        </template>
      </el-table-column>
      <el-table-column
        label="โต๊ด"
        header-align="center"
        align="center"
        prop="priceFlib"
      >
        <template slot="header">
          <span @click="sortTable('priceFlib')" class="table-header"
            >โต๊ด
            <i
              v-if="sortColumn === 'priceFlib'"
              :class="sort === 1 ? 'el-icon-bottom' : 'el-icon-top'"
            ></i
          ></span>
        </template>
        <template slot-scope="scope">
          {{ scope.row.priceFlib | commaInteger }}
        </template>
      </el-table-column>
      <el-table-column
        label="ประเภท"
        prop="type"
        header-align="center"
        align="center"
      >
        <template slot="header">
          <span @click="sortTable('type')" class="table-header"
            >ประเภท
            <i
              v-if="sortColumn === 'type'"
              :class="sort === 1 ? 'el-icon-bottom' : 'el-icon-top'"
            ></i
          ></span>
        </template>
        <template slot-scope="scope">
          {{ scope.row.type === 1 ? "บน" : "ล่าง" }}
        </template>
      </el-table-column>

      <el-table-column
        label="รวม"
        prop="total"
        header-align="center"
        align="center"
      >
        <template slot="header">
          <span @click="sortTable('total')" class="table-header"
            >รวม
            <i
              v-if="sortColumn === 'total'"
              :class="sort === 1 ? 'el-icon-bottom' : 'el-icon-top'"
            ></i
          ></span>
        </template>
        <template slot-scope="scope">
          {{ scope.row.total | commaInteger }} บาท
        </template>
      </el-table-column>
    </el-table>

    <div class="pd-y-4 t-center">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="page"
        :page-size="limit"
        layout="prev,next,jumper"
        :total="lotterySumNumbers.all.length"
      >
      </el-pagination>
    </div>

    <el-dialog
      title="รายการหลัก"
      :visible.sync="dialogLotteryVisible"
      :fullscreen="true"
    >
      <div class="t-right pd-b-4 action-box">
        <el-button
          @click="delLottery(multipleSelectionSub, 2)"
          :loading="loadingDel"
          type="danger"
          icon="el-icon-delete"
          size="mini"
          plain
          class="mg-x-4"
        >
          ลบที่เลือก
          <span v-if="multipleSelectionSub.length > 0"
            >({{ multipleSelectionSub.length }})</span
          >
        </el-button>
      </div>
      <el-table
        :data="lotterySub"
        @selection-change="handleSelectionSubChange"
        style="width: 100%"
        border
        :default-sort="{ prop: 'createdAt', order: 'descending' }"
      >
        <el-table-column type="selection" width="35"> </el-table-column>
        <el-table-column
          label="เลข"
          prop="number"
          header-align="center"
          align="center"
          sortable
        >
          <template slot-scope="scope">
            <el-button
              @click="
                (lotterySub2 = scope.row.sub), (dialogLotterySub2Visible = true)
              "
              type="text"
              >{{ scope.row.number }}</el-button
            >
          </template>
        </el-table-column>
        <el-table-column
          label="เต็ง"
          header-align="center"
          align="center"
          prop="price"
          sortable
        >
          <template slot-scope="scope">
            {{ scope.row.price | commaInteger }}
          </template>
        </el-table-column>
        <el-table-column
          label="โต๊ด"
          header-align="center"
          align="center"
          prop="priceFlib"
          sortable
        >
          <template slot-scope="scope">
            {{ scope.row.priceFlib | commaInteger }}
          </template>
        </el-table-column>
        <el-table-column
          label="ประเภท"
          prop="type"
          header-align="center"
          align="center"
          sortable
        >
          <template slot-scope="scope">
            {{ scope.row.type === 1 ? "บน" : "ล่าง" }}
          </template>
        </el-table-column>

        <el-table-column
          label="รวม"
          prop="total"
          header-align="center"
          align="center"
          sortable
        >
          <template slot-scope="scope">
            {{ scope.row.total | commaInteger }} บาท
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>

    <el-dialog
      title="รายการย่อย"
      :visible.sync="dialogLotterySub2Visible"
      :fullscreen="true"
    >
      <div class="t-right pd-b-4 action-box">
        <el-button
          @click="delLottery(multipleSelectionSub2, 3)"
          :loading="loadingDel"
          type="danger"
          icon="el-icon-delete"
          size="mini"
          plain
          class="mg-x-4"
        >
          ลบที่เลือก
          <span v-if="multipleSelectionSub2.length > 0"
            >({{ multipleSelectionSub2.length }})</span
          >
        </el-button>
      </div>
      <el-table
        :data="lotterySub2"
        @selection-change="handleSelectionSub2Change"
        style="width: 100%"
        border
        :default-sort="{ prop: 'createdAt', order: 'descending' }"
      >
        <el-table-column type="selection" width="35"> </el-table-column>
        <el-table-column
          label="เลข"
          prop="number"
          header-align="center"
          align="center"
          sortable
        >
        </el-table-column>
        <el-table-column
          label="เต็ง"
          header-align="center"
          align="center"
          prop="price"
          sortable
        >
          <template slot-scope="scope">
            {{ scope.row.price | commaInteger }}
          </template>
        </el-table-column>
        <el-table-column
          label="โต๊ด"
          header-align="center"
          align="center"
          prop="priceFlib"
          sortable
        >
          <template slot-scope="scope">
            {{ scope.row.priceFlib | commaInteger }}
          </template>
        </el-table-column>
        <el-table-column
          label="ประเภท"
          prop="type"
          header-align="center"
          align="center"
          sortable
        >
          <template slot-scope="scope">
            {{ scope.row.type === 1 ? "บน" : "ล่าง" }}
          </template>
        </el-table-column>

        <el-table-column
          label="วันที่สร้าง"
          prop="createdAt"
          header-align="center"
          align="center"
          sortable
          width="250"
        >
          <template slot-scope="scope">
            {{ scope.row.createdAt | dateTimeTh }}
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>

    <el-dialog
      title="เลขดัง"
      :visible.sync="dialogHotListVisible"
      :fullscreen="true"
    >
      <div>
        <div>
          <h3 class="t-left mg-t-less">
            เลขดัง 1

            <el-button
              @click="openDialogHotVisible(1)"
              type="success"
              icon="el-icon-plus"
              size="small"
              >เพิ่ม</el-button
            >
            <el-button
              @click="delHotMulti(hotTwoNumber1, hotThreeNumber1)"
              type="danger"
              icon="el-icon-delete"
              size="small"
              >ลบทั้งหมด</el-button
            >
          </h3>

          <div v-loading="loadingTableHot1">
            <div class="pd-b-4">
              <el-collapse v-model="collapseHotAll1">
                <el-collapse-item title="เลขดัง 1" name="1">
                  <el-collapse v-model="collapseHot1">
                    <el-collapse-item title="เลข 2 ตัว" name="1">
                      <el-row v-if="hotTwoNumber1.length > 0" :gutter="20">
                        <el-col
                          v-for="item in hotTwoNumber1"
                          :key="item._id"
                          :xl="1"
                          :lg="2"
                          :md="2"
                          :sm="3"
                          :xs="6"
                          class="pd-y-less"
                        >
                          <div class="hot-box">
                            <span class="pd-x-5">{{ item.number }}</span>
                            <el-button
                              @click="delHot(item._id)"
                              type="text "
                              size="mini"
                              class="danger"
                              >ลบ</el-button
                            >
                          </div>
                        </el-col>
                      </el-row>
                      <span v-else class="pd-y-4">*** ไม่มีข้อมูล ***</span>
                    </el-collapse-item>
                    <el-collapse-item title="เลข 3 ตัว" name="2">
                      <el-row v-if="hotThreeNumber1.length > 0" :gutter="20">
                        <el-col
                          v-for="item in hotThreeNumber1"
                          :key="item._id"
                          :xl="1"
                          :lg="2"
                          :md="2"
                          :sm="3"
                          :xs="6"
                          class="pd-y-less"
                        >
                          <div class="hot-box">
                            <span class="pd-x-5">{{ item.number }}</span>
                            <el-button
                              @click="delHot(item._id)"
                              type="text "
                              size="mini"
                              class="danger"
                              >ลบ</el-button
                            >
                          </div>
                        </el-col>
                      </el-row>
                      <span v-else class="pd-y-4">*** ไม่มีข้อมูล ***</span>
                    </el-collapse-item>
                  </el-collapse>
                </el-collapse-item>
              </el-collapse>
            </div>
          </div>
        </div>

        <div>
          <h3 class="t-left mg-t-less">
            เลขดัง 2

            <el-button
              @click="openDialogHotVisible(2)"
              type="success"
              icon="el-icon-plus"
              size="small"
              >เพิ่ม</el-button
            >
            <el-button
              @click="delHotMulti(hotTwoNumber2, hotThreeNumber2)"
              type="danger"
              icon="el-icon-delete"
              size="small"
              >ลบทั้งหมด</el-button
            >
          </h3>

          <div v-loading="loadingTableHot2">
            <div class="pd-b-4">
              <el-collapse v-model="collapseHotAll2">
                <el-collapse-item title="เลขดัง 2" name="1">
                  <el-collapse v-model="collapseHot2">
                    <el-collapse-item title="เลข 2 ตัว" name="1">
                      <el-row v-if="hotTwoNumber2.length > 0" :gutter="20">
                        <el-col
                          v-for="item in hotTwoNumber2"
                          :key="item._id"
                          :xl="1"
                          :lg="2"
                          :md="2"
                          :sm="3"
                          :xs="6"
                          class="pd-y-less"
                        >
                          <div class="hot-box">
                            <span class="pd-x-5">{{ item.number }}</span>
                            <el-button
                              @click="delHot(item._id)"
                              type="text "
                              size="mini"
                              class="danger"
                              >ลบ</el-button
                            >
                          </div>
                        </el-col>
                      </el-row>
                      <span v-else class="pd-y-4">*** ไม่มีข้อมูล ***</span>
                    </el-collapse-item>
                    <el-collapse-item title="เลข 3 ตัว" name="2">
                      <el-row v-if="hotThreeNumber2.length > 0" :gutter="20">
                        <el-col
                          v-for="item in hotThreeNumber2"
                          :key="item._id"
                          :xl="1"
                          :lg="2"
                          :md="2"
                          :sm="3"
                          :xs="6"
                          class="pd-y-less"
                        >
                          <div class="hot-box">
                            <span class="pd-x-5">{{ item.number }}</span>
                            <el-button
                              @click="delHot(item._id)"
                              type="text "
                              size="mini"
                              class="danger"
                              >ลบ</el-button
                            >
                          </div>
                        </el-col>
                      </el-row>
                      <span v-else class="pd-y-4">*** ไม่มีข้อมูล ***</span>
                    </el-collapse-item>
                  </el-collapse>
                </el-collapse-item>
              </el-collapse>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>

    <el-dialog
      :title="`เพิ่มเลขดัง${hotType === 1 ? ' 1' : ' 2'}`"
      :visible.sync="dialogHotVisible"
      :fullscreen="true"
    >
      <el-form :model="formHot" ref="formHot" class="lottery-box pd-b-3">
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item label="เลข" prop="number">
              <el-input
                v-model="formHot.number"
                @keyup.enter.native="submitFormHot"
                type="number"
                maxlength="3"
                @input="replaceCharHot"
                id="numberHot"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24" class="pd-y-4">
            <el-button
              @click="submitFormHot"
              type="primary"
              class="save"
              :loading="loadingSaveHot"
              ><span class="pd-x-4">เพิ่ม</span></el-button
            >
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import "element-ui/lib/theme-chalk/display.css";
import { HTTP } from "./axios.js";
export default {
  name: "App",
  computed: {
    lotterySumNumbers() {
      let lotterys = this.lotterys.filter(
        (f) =>
          this.validFilter(this.filter.number, f.number, "text") &&
          this.validFilter(this.filter.unit, f.number.length, "array") &&
          this.validFilter(this.filter.type, f.type, "array")
      );

      let sorts = lotterys.sort((a, b) => {
        if (a[this.sortColumn] > b[this.sortColumn]) return -1 * this.sort;
        if (a[this.sortColumn] < b[this.sortColumn]) return 1 * this.sort;
        return 0;
      });

      return {
        limit: sorts.slice(
          (this.page - 1) * this.limit,
          this.page * this.limit
        ),
        all: sorts,
      };
    },

    total() {
      let total = 0;
      this.lotterySumNumbers.all.map((item) => {
        total = total + item.price + item.priceFlib;
      });

      return total;
    },
    hotTwoNumber1() {
      return this.hot.filter(
        (f) =>
          f.number.length == 2 &&
          (typeof f.type === "undefined" || f.type === 1)
      );
    },
    hotThreeNumber1() {
      return this.hot.filter(
        (f) =>
          f.number.length == 3 &&
          (typeof f.type === "undefined" || f.type === 1)
      );
    },
    hotTwoNumber2() {
      return this.hot.filter(
        (f) =>
          f.number.length == 2 && typeof f.type !== "undefined" && f.type === 2
      );
    },
    hotThreeNumber2() {
      return this.hot.filter(
        (f) =>
          f.number.length == 3 && typeof f.type !== "undefined" && f.type === 2
      );
    },
  },
  data() {
    return {
      dialogHotListVisible: false,
      dialogHotVisible: false,
      dialogLotteryVisible: false,
      dialogLotterySub2Visible: false,
      loadingSave: false,
      loadingDel: false,
      loadingTable: true,
      loadingSaveHot: false,
      loadingTableHot1: true,
      loadingTableHot2: true,
      filter: {
        number: null,
        unit: [1, 2, 3],
        type: [0, 1],
        condition: [],
      },
      formLottery: {
        number: "",
        price: 0,
        priceFlib: 0,
        type: [1],
      },
      formHot: {
        number: "",
        type: [0, 1],
      },
      hot: [],
      hotType: null,
      lotterys: [
        // { id: 1, number: "75", price: 100, priceFlib: 100, type: 1 },
        // { id: 2, number: "75", price: 100, priceFlib: 100, type: 1 },
        // { id: 3, number: "57", price: 100, priceFlib: 100, type: 1 },
        // { id: 4, number: "57", price: 100, priceFlib: 100, type: 1 },
        // { id: 5, number: "75", price: 100, priceFlib: 100, type: 1 },
        // {id:6,number:'833',price:100,priceFlib:100,type:1},
        // {id:7,number:'388',price:100,priceFlib:100,type:1},
        // {id:8,number:'52',price:100,priceFlib:100,type:0},
        // {id:9,number:'123',price:100,priceFlib:100,type:1},
        // {id:10,number:'777',price:100,priceFlib:100,type:1},
        // {id:11,number:'312',price:100,priceFlib:100,type:1},
        // {id:12,number:'231',price:100,priceFlib:100,type:1},
        // {id:13,number:'321',price:100,priceFlib:100,type:1},
        // {id:9,number:'123',price:0,priceFlib:100,type:0},
        // {id:10,number:'213',price:0,priceFlib:100,type:0},
        // {id:11,number:'312',price:0,priceFlib:100,type:1},
        // {id:12,number:'231',price:0,priceFlib:100,type:1},
        // {id:13,number:'321',price:0,priceFlib:100,type:1},
        // {id:14,number:'123',price:100,priceFlib:0,type:1},
        // {id:15,number:'213',price:100,priceFlib:0,type:1},
        // {id:16,number:'312',price:100,priceFlib:0,type:1},
        // {id:17,number:'231',price:100,priceFlib:0,type:0},
        // {id:18,number:'321',price:100,priceFlib:0,type:0},
        // {id:19,number:'321',price:100,priceFlib:0,type:0},
        // {id:20,number:'321',price:0,priceFlib:100,type:0},
      ],
      lotterySub: [],
      lotterySub2: [],
      multipleSelection: [],
      multipleSelectionSub: [],
      multipleSelectionSub2: [],
      collapseHotAll1: [],
      collapseHot1: [],
      collapseHotAll2: [],
      collapseHot2: [],
      page: 1,
      limit: 20,
      sortColumn: "total",
      sort: 1,
      lotteryConst: [],
    };
  },
  mounted() {
    this.fetchHot();
    this.fetchLottery();
  },
  methods: {
    validFilter(query, value, type) {
      if (type === "text") {
        return (
          !query ||
          (query && value.toLowerCase().indexOf(query.toLowerCase()) > -1)
        );
      } else if (type === "array") {
        return (
          query.length === 0 ||
          (query.length > 0 &&
            typeof query.find((f) => f === value) !== "undefined")
        );
      } else if (type === "more") {
        return !query || (query && Number(value) >= Number(query));
      } else {
        return true;
      }
    },
    filterLottery() {
      let more500 =
        typeof this.filter.condition.find((f) => f === 1) !== "undefined"
          ? 500
          : "";
      let limit3 =
        typeof this.filter.condition.find((f) => f === 2) !== "undefined"
          ? 3
          : "";
      let more600 =
        typeof this.filter.condition.find((f) => f === 3) !== "undefined"
          ? 600
          : "";

      console.log("lotteryConst", this.lotteryConst);
      let lotterys = [];
      this.lotteryConst.map((item) => {
        let obj = JSON.parse(JSON.stringify(item));
        let subs = [];
        let price = 0;
        let priceFlib = 0;

        obj.sub.map((sub) => {
          if (
            validateFilter(more500, sub.price, "price") &&
            validateFilter(more600, sub.price, "price")
          ) {
            subs.push(sub);
          }
        });

        if (limit3) {
          subs = subs.sort((a, b) => {
            if (a.price > b.price) return -1;
            if (a.price < b.price) return 1;
            return 0;
          });

          subs = subs.slice(0, limit3);
        }

        subs.map((sub) => {
          price += sub.price;
          priceFlib += sub.priceFlib;
        });

        if (subs.length > 0) {
          obj.sub = subs;
          obj.price = price;
          obj.priceFlib = priceFlib;
          obj.total = price + priceFlib;

          lotterys.push(obj);
        }
      });

      function validateFilter(query, value, type) {
        if (type === "price") {
          return !query || (query && value >= query);
        } else {
          return true;
        }
      }

      this.lotterys = lotterys;
    },
    openDialogHotVisible(type) {
      this.dialogHotVisible = true;
      this.hotType = type;
    },
    addPriceOne(price) {
      if (!this.formLottery.price) {
        this.formLottery.price = price;
      } else if (!this.formLottery.priceFlib) {
        this.formLottery.priceFlib = price;
      }

      if (this.formLottery.price && this.formLottery.priceFlib) {
        this.submitForm();
      }
    },
    addPrice(price) {
      this.formLottery.price = price;
      this.formLottery.priceFlib = price;

      this.submitForm();
    },
    sortTable(props) {
      if (this.sortColumn === props) {
        this.sort = this.sort === -1 ? 1 : -1;
      } else {
        this.sort = 1;
      }
      this.sortColumn = props;
    },
    handleSizeChange(val) {
      this.limit = val;
    },
    handleCurrentChange(val) {
      this.page = val;
    },
    fetchLottery() {
      HTTP.get(`get/all/lottery`)
        .then((res) => {
          if (res.data.success) {
            let math = [];
            let lotterys = JSON.parse(JSON.stringify(res.data.obj));
            lotterys.map((item) => {
              let filterMath = math.filter(
                (f) =>
                  f.type === item.type &&
                  this.checkFormat(f) === this.checkFormat(item) &&
                  (!this.checkNumberTongDouble(item.number) ||
                    (this.checkNumberTongDouble(item.number) &&
                      f.number === item.number)) &&
                  (((this.checkFormat(item) === 1 ||
                    this.checkFormat(item) === 2) &&
                    this.checkFlip(f.number, item.number)) ||
                    (this.checkFormat(item) === 3 && f.number === item.number))
              );

              if (filterMath.length == 0) {
                let filter = lotterys.filter(
                  (f) =>
                    f.type === item.type &&
                    this.checkFormat(f) === this.checkFormat(item) &&
                    (!this.checkNumberTongDouble(item.number) ||
                      (this.checkNumberTongDouble(item.number) &&
                        f.number === item.number)) &&
                    (((this.checkFormat(item) === 1 ||
                      this.checkFormat(item) === 2) &&
                      this.checkFlip(f.number, item.number)) ||
                      (this.checkFormat(item) === 3 &&
                        f.number === item.number))
                );

                let price = 0;
                let priceFlib = 0;
                let itemMaximum = {
                  number: "",
                  total: 0,
                };

                let mathTwo = [];
                filter.map((m) => {
                  price = price + m.price;
                  priceFlib = priceFlib + m.priceFlib;
                  if (m.price + m.priceFlib > itemMaximum.total) {
                    itemMaximum = {
                      number: m.number,
                      total: m.price + m.priceFlib,
                    };
                  }

                  let filterMathTwo = mathTwo.filter(
                    (f) => f.number === m.number
                  );

                  if (filterMathTwo.length == 0) {
                    let filterTwo = filter.filter((f) => f.number === m.number);
                    let priceTwo = 0;
                    let priceFlibTwo = 0;
                    filterTwo.map((m) => {
                      priceTwo = priceTwo + m.price;
                      priceFlibTwo = priceFlibTwo + m.priceFlib;
                    });

                    m["sub"] = JSON.parse(JSON.stringify(filterTwo));
                    m["price"] = priceTwo;
                    m["priceFlib"] = priceFlibTwo;
                    m["total"] = priceTwo + priceFlibTwo;

                    mathTwo.push(m);
                  }
                });

                item["sub"] = JSON.parse(JSON.stringify(mathTwo));
                item["number"] = itemMaximum.number;
                item["price"] = price;
                item["priceFlib"] = priceFlib;
                item["total"] = price + priceFlib;

                math.push(item);
              }
            });

            this.lotterys = math;
            this.lotteryConst = math;
          } else {
            this.lotterys = [];
            this.lotteryConst = [];
          }
        })
        .finally(() => {
          this.loadingTable = false;
        });
    },
    submitForm() {
      if (!this.formLottery.number || this.formLottery.number.length <= 1) {
        this.$message.error("กรุณากรอกเลข");
        return false;
      }

      if (this.formLottery.price === 0 && this.formLottery.priceFlib === 0) {
        this.$message.error("กรุณากรอกราคาเต็งหรือโต๊ด");
        return false;
      }

      if (this.formLottery.type.length === 0) {
        this.$message.error("กรุณาเลือกประเภท");
        return false;
      }

      this.loadingSave = true;
      this.loadingTable = true;

      this.formLottery.type.map((item) => {
        let obj = {
          number: this.formLottery.number.toString(),
          price: this.formLottery.price,
          priceFlib: this.formLottery.priceFlib,
          type: item,
        };

        HTTP.post(`create/lottery`, obj)
          .then((res) => {
            if (res.data.success) {
              this.$message({
                message: "เพิ่มข้อมูลเรียบร้อยแล้ว",
                type: "success",
              });
              this.formLottery = {
                number: "",
                price: 0,
                priceFlib: 0,
                type: [1],
              };
              this.fetchLottery();
              document.getElementById("number").focus();
            } else {
              this.$message.error("เพิ่มข้อมูลล้มเหลว กรุณาลองใหม่");
            }
          })
          .catch((e) => {
            this.$message.error("เพิ่มข้อมูลล้มเหลว กรุณาลองใหม่");
            console.log(e);
          })
          .finally(() => {
            this.loadingSave = false;
          });
      });
    },
    delLottery(selectTion, level) {
      this.loadingTable = true;
      this.loadingDel = true;

      let selected = [];
      selectTion.map((item) => {
        if (level === 1) {
          item.sub.map((item2) => {
            selected = selected.concat(item2.sub);
          });
        } else if (level === 2) {
          selected = selected.concat(item.sub);
        } else if (level === 3) {
          selected.push(item);
        } else {
          return null;
        }
      });

      let del = [];
      selected.map((item) => {
        del.push(item._id);
      });

      let obj = {
        lottery: del,
      };

      HTTP.post(`delete/lottery`, obj)
        .then((res) => {
          if (res.data.success) {
            this.$message({
              message: "ลบข้อมูลเรียบร้อยแล้ว",
              type: "success",
            });
            this.fetchLottery();
            this.dialogLotteryVisible = false;
          } else {
            this.$message.error("ลบข้อมูลล้มเหลว กรุณาลองใหม่");
          }
        })
        .catch((e) => {
          this.$message.error("ลบข้อมูลล้มเหลว กรุณาลองใหม่");
          console.log(e);
        })
        .finally(() => {
          this.loadingDel = false;
        });
    },
    fetchHot() {
      HTTP.get(`get/all/hot`)
        .then((res) => {
          if (res.data.success) {
            this.hot = res.data.obj;
          }
        })
        .finally(() => {
          this.loadingTableHot1 = false;
          this.loadingTableHot2 = false;
        });
    },
    submitFormHot() {
      if (!this.formHot.number) {
        this.$message.error("กรุณากรอกเลข");
        return false;
      }
      this.loadingSaveHot = true;
      this.loadingTableHot1 = true;
      this.loadingTableHot2 = true;

      let numberFlip = [];
      if (this.formHot.number.length === 3) {
        numberFlip = this.getFlip(3, this.formHot.number);
      }

      let obj = {
        number: this.formHot.number.toString(),
        numberFlip: numberFlip,
        type: this.hotType,
      };

      HTTP.post(`create/hot`, obj)
        .then((res) => {
          if (res.data.success) {
            this.$message({
              message: "เพิ่มเลขดังเรียบร้อยแล้ว",
              type: "success",
            });
            this.dialogHotVisible = false;
            this.formHot = {
              number: "",
              type: [0, 1],
            };
            this.fetchHot();
            document.getElementById("numberHot").focus();
          } else {
            this.$message.error("เพิ่มเลขดังล้มเหลว กรุณาลองใหม่");
          }
        })
        .catch((e) => {
          this.$message.error("เพิ่มเลขดังล้มเหลว กรุณาลองใหม่");
          console.log(e);
        })
        .finally(() => {
          this.loadingSaveHot = false;
        });
    },
    delHot(id) {
      this.loadingTableHot1 = true;
      this.loadingTableHot2 = true;
      HTTP.delete(`delete/hot/${id}`)
        .then((res) => {
          console.log("delHot", res);
          if (res.data.success) {
            this.$message({
              message: "ลบเลขดังเรียบร้อยแล้ว",
              type: "success",
            });
            this.fetchHot();
            this.loadingTableHot1 = false;
            this.loadingTableHot2 = false;
          } else {
            this.$message.error("ลบเลขดังล้มเหลว กรุณาลองใหม่");
          }
        })
        .catch((e) => {
          this.$message.error("ลบเลขดังล้มเหลว กรุณาลองใหม่");
          console.log(e);
        })
        .finally(() => {
          this.loadingDel = false;
        });
    },
    delHotMulti(hotTwoNumber, hotThreeNumber) {
      this.loadingTableHot1 = true;
      this.loadingTableHot2 = true;

      let dels = [];
      hotTwoNumber.map((item) => {
        dels.push(item._id);
      });

      hotThreeNumber.map((item) => {
        dels.push(item._id);
      });

      HTTP.post(`delete/hots`, { hots: dels })
        .then((res) => {
          if (res.data.success) {
            this.$message({
              message: "ลบเลขดังเรียบร้อยแล้ว",
              type: "success",
            });
            this.fetchHot();
            this.loadingTableHot1 = false;
            this.loadingTableHot2 = false;
          } else {
            this.$message.error("ลบเลขดังล้มเหลว กรุณาลองใหม่");
          }
        })
        .catch((e) => {
          this.$message.error("ลบเลขดังล้มเหลว กรุณาลองใหม่");
          console.log(e);
        })
        .finally(() => {
          this.loadingDel = false;
        });
    },
    toggleSelection(rows) {
      if (rows) {
        rows.forEach((row) => {
          this.$refs.formLottery.toggleRowSelection(row);
        });
      } else {
        this.$refs.formLottery.clearSelection();
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleSelectionSubChange(val) {
      this.multipleSelectionSub = val;
    },
    handleSelectionSub2Change(val) {
      this.multipleSelectionSub2 = val;
    },
    checkFlip(number, numberCheck) {
      if (number.length !== numberCheck.length) {
        return false;
      }

      let numbers = numberCheck.split("");
      let numberFlip = this.getFlip(number.length, numbers);

      let find = numberFlip.find((f) => number === f);
      return typeof find !== "undefined" ? true : false;
    },
    getFlip(length, numbers) {
      let numberFlip = [];
      if (length === 3) {
        numberFlip.push(numbers[0] + "" + numbers[1] + "" + numbers[2]);
        numberFlip.push(numbers[0] + "" + numbers[2] + "" + numbers[1]);
        numberFlip.push(numbers[1] + "" + numbers[0] + "" + numbers[2]);
        numberFlip.push(numbers[1] + "" + numbers[2] + "" + numbers[0]);
        numberFlip.push(numbers[2] + "" + numbers[0] + "" + numbers[1]);
        numberFlip.push(numbers[2] + "" + numbers[1] + "" + numbers[0]);
      } else {
        numberFlip.push(numbers[0] + "" + numbers[1]);
        numberFlip.push(numbers[1] + "" + numbers[0]);
      }

      return numberFlip;
    },
    checkNumberTongDouble(number) {
      let numbers = number.split("");
      let count = 0;
      for (let index = 0; index < numbers.length; index++) {
        if (numbers[0] == numbers[index]) {
          count = count + 1;
        }
      }

      return count === numbers.length;
    },
    checkFormat(number) {
      if (number.price > 0 && number.priceFlib > 0) {
        return 1;
      } else if (number.price === 0 && number.priceFlib > 0) {
        return 2;
      } else if (number.price > 0 && number.priceFlib === 0) {
        return 3;
      } else {
        return null;
      }
    },
    replaceChar() {
      if (this.formLottery.number.length > 3) {
        this.formLottery.number = this.formLottery.number.slice(0, 3);
      } else {
        this.formLottery.number = this.formLottery.number.replace(
          /[^0-9]/g,
          ""
        );
      }
    },
    replaceCharHot() {
      if (this.formHot.number.length > 3) {
        this.formHot.number = this.formHot.number.slice(0, 3);
      } else {
        this.formHot.number = this.formHot.number.replace(/[^0-9]/g, "");
      }
    },
    csvExport() {
      let title = ["", "เลข", "เต็ง", "โต๊ด", "ประเภท", "รวมโต๊ด", "รวม"];
      let datas = [];
      let maths = [];

      this.lotterySumNumbers.all.map((item) => {
        if (item.number.length < 3) {
          let checkMath = maths.find(
            (f) => f.number === item.number && f.type === item.type
          );
          if (typeof checkMath === "undefined") {
            let numbers = this.lotterySumNumbers.all.filter(
              (f) =>
                (f.number === item.number ||
                  this.checkFlip(f.number, item.number)) &&
                f.type === item.type
            );

            maths = maths.concat(numbers);
            let normals = {
              number: "",
              price: 0,
              priceFlib: 0,
              type: "",
              total: 0,
            };
            let flips = {
              number: "",
              price: 0,
              priceFlib: 0,
              type: "",
              total: 0,
            };
            numbers.map((n) => {
              if (n.number === item.number) {
                normals.number = n.number;
                normals.price = normals.price + n.price;
                normals.priceFlib = normals.priceFlib + n.priceFlib;
                normals.type = n.type;
                normals.total = normals.total + n.total;
              } else {
                flips.number = n.number;
                flips.price = flips.price + n.price;
                flips.priceFlib = flips.priceFlib + n.priceFlib;
                flips.type = n.type;
                flips.total = flips.total + n.total;
              }
            });

            let findHot1 = this.hot.find(
              (f) =>
                (f.number == normals.number || f.number == flips.number) &&
                (typeof f.type === "undefined" || f.type === 1)
            );
            let findHot2 = this.hot.find(
              (f) =>
                (f.number == normals.number || f.number == flips.number) &&
                typeof f.type !== "undefined" &&
                f.type === 2
            );
            let hotText = "";
            if (typeof findHot1 !== "undefined") {
              hotText += "เลขดัง 1";
            }

            if (typeof findHot2 !== "undefined") {
              console.log("findHot2", findHot2);
              if (hotText) {
                hotText += ", ";
              }
              hotText += "เลขดัง 2";
            }

            let numberText =
              normals.total > flips.total ? normals.number : flips.number;

            datas.push({
              hot: '"' + hotText + '"',
              number: '="' + numberText + '"',
              price: normals.price + flips.price,
              priceFlib: normals.priceFlib + flips.priceFlib,
              type: item.type === 1 ? "บน" : "ล่าง",
              totalFlib: item.priceFlib,
              total: normals.total + flips.total,
            });
          }
        } else {
          let sort = item.sub.sort((a, b) => {
            if (a.total > b.total) return -1;
            if (a.total < b.total) return 1;
            return 0;
          });

          // let subs = [];
          // if (sort.length > 3 && sort[3].total >= 500) {
          //   subs = sort.slice(0, 3);
          // } else {
          //   subs = sort.filter((f) => f.total >= 500);
          // }

          sort.filter((s, index) => {
            let findHot1 = this.hot.find(
              (f) =>
                f.number == s.number &&
                (typeof f.type === "undefined" || f.type === 1)
            );
            let findHot2 = this.hot.find(
              (f) =>
                f.number == s.number &&
                typeof f.type !== "undefined" &&
                f.type === 2
            );
            let hotText = "";
            if (typeof findHot1 !== "undefined") {
              hotText += "เลขดัง 1";
            }

            if (typeof findHot2 !== "undefined") {
              if (hotText) {
                hotText += ", ";
              }
              hotText += "เลขดัง 2";
            }

            datas.push({
              hot: '"' + hotText + '"',
              number: '="' + s.number + '"',
              price: s.price,
              priceFlib: s.priceFlib,
              type: s.type === 1 ? "บน" : "ล่าง",
              totalFlib: index === 0 ? item.priceFlib : "-",
              total: s.total,
            });
          });
        }
      });

      datas = datas.sort((a, b) => {
        if (a.total > b.total) return -1;
        if (a.total < b.total) return 1;
        return 0;
      });

      let csvContent = "";
      csvContent += [
        title.join(","),
        ...datas.map((item) => Object.values(item).join(",")),
      ]
        .join("\n")
        .replace(/(^\[)|(\]$)/gm, "");

      var link = window.document.createElement("a");
      link.setAttribute(
        "href",
        "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURI(csvContent)
      );
      link.setAttribute(
        "download",
        "lucky_wealthy_" +
          new Date().getDate() +
          "-" +
          (new Date().getMonth() + 1) +
          "-" +
          new Date().getFullYear() +
          ".csv"
      );
      link.click();
    },
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  padding: 5px 20px 0 20px;
}

@media screen and (max-width: 1024px) {
  .el-col.top-30 {
    padding-top: 30px;
  }
}

@media screen and (max-width: 767px) {
  button.save {
    width: 100%;
  }

  .el-col {
    padding-top: 30px;
  }

  .el-col.top-input {
    padding-top: 10px;
  }

  .el-col.y-space {
    padding-top: 5px;
  }

  .action-box.main {
    display: flex;
    flex-wrap: wrap;
  }

  .btn-menu {
    width: 100%;
    margin: 5px 0 !important;
  }
  .button-popconfirm {
    margin-left: 0 !important;
    width: 100%;
  }

  .mg-y-less-only-mobile {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
}

.btn-menu {
  margin: 0 5px;
}

.button-popconfirm {
  margin-left: 10px;
}

.price-box {
  display: flex;
  align-items: center;
  justify-content: center;
}
.hot-box {
  display: flex;
  align-items: baseline;
  justify-content: center;
}
.el-form-item {
  margin: 0 !important;
  position: relative;
}
.el-form-item__label {
  position: absolute;
  left: 0;
  top: -35px;
}

.el-col {
  text-align: left;
}

.el-col.flex {
  height: 40px;
  display: flex;
  align-items: center;
}

.pd-x-5 {
  padding: 0 5px 0 5px;
}

.pd-b-3 {
  padding: 0 0 15px 0;
}

.pd-b-4 {
  padding: 0 0 10px 0;
}

.pd-x-4 {
  padding: 0 10px 0 10px;
}

.pd-y-4 {
  padding: 10px 0 10px 0;
}

.pd-y-5 {
  padding: 5px 0 5px 0;
}

.pd-l-4 {
  padding-left: 10px;
}

.pd-y-less {
  padding-top: 0;
  padding-bottom: 0;
}
.mg-t-less {
  margin-top: 0 !important;
}

.mg-y-less {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.mg-l-4 {
  margin-left: 10px !important;
}

.mg-x-4 {
  margin: 0 10px 0 10px !important;
}

.mg-b-4 {
  margin-bottom: 10px !important;
}

.mg-b-3 {
  margin-bottom: 15px !important;
}

.mg-b-less {
  margin-bottom: 0 !important;
}

input.number {
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 40px;
  line-height: 40px;
  outline: 0;
  padding: 0 15px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;
}
.t-right {
  text-align: right;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.el-button--text.danger {
  font-size: 10px;
  color: #f56c6c;
  padding: 0;
}

.el-button--text.danger:active,
.el-button--text.danger:hover {
  color: #dd6161;
}

.table-header {
  cursor: pointer;
}

.t-center {
  text-align: center;
}
</style>
