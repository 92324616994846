import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import store from "./store";
import VueNumeric from 'vue-numeric'
import numeral from "numeral";
import moment from "moment";
import locale from 'element-ui/lib/locale/lang/en'

Vue.filter("commaInteger", (value) => {
  return numeral(value).format("0,0");
});

Vue.filter("dateTimeTh", function(date) {
  let res = "";
  if (typeof date === "undefined" || date === null || date === "") {
    res = "-";
  } else {
    let dateMoment  = moment(date)
      .locale("th")
      .format("LLL น.");
    let sortYear = new Date(date).getFullYear() + 543;
    res = dateMoment.replace(
      /[0-9]+[0-9]+[0-9]+[0-9]/g,
      sortYear.toString()
    );
  }
  return res;
});

Vue.config.productionTip = false
Vue.use(ElementUI,{ locale });
Vue.use(VueNumeric)

new Vue({
  store,
  render: h => h(App),
}).$mount('#app')
